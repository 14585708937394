import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Node-RED_Tutorials/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Run a INSTAR Node-RED Dashboard in Docker",
  "path": "/Advanced_User/Node-RED_in_Docker/",
  "dateChanged": "2020-06-29",
  "author": "Mike Polinowski",
  "excerpt": "Node-RED is a programming tool for wiring together hardware devices like your INSTAR IP camera and APIs & online services. It provides a browser-based editor that makes it easy to wire together flows using the wide range of nodes providing inter-connectivity between your cameras REST API and IoT protocols like MQTT (Message Queuing Telemetry Transport) Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD.",
  "image": "./AU_SearchThumb_Node-RED.png",
  "social": "/images/Search/AU_SearchThumb_Node-RED.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_NodeRED_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Node-RED"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Run a INSTAR Node-RED Dashboard in Docker' dateChanged='2020-06-29' author='Mike Polinowski' tag='INSTAR IP Camera' description='Node-RED is a programming tool for wiring together hardware devices like your INSTAR IP camera and APIs & online services. It provides a browser-based editor that makes it easy to wire together flows using the wide range of nodes providing inter-connectivity between your cameras REST API and your Smarthome.' image='/images/Search/AU_SearchThumb_Node-RED.png' twitter='/images/Search/AU_SearchThumb_Node-RED.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Node-RED_in_Docker/' locationFR='/fr/Advanced_User/Node-RED_in_Docker/' crumbLabel="Node-RED" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "run-a-instar-node-red-dashboard-in-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#run-a-instar-node-red-dashboard-in-docker",
        "aria-label": "run a instar node red dashboard in docker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Run a INSTAR Node-RED Dashboard in Docker`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#install-docker-on-ubuntu-2004"
        }}>{`Install Docker on Ubuntu 20.04`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#download-node-red-configuration"
        }}>{`Download Node-RED Configuration`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#run-node-red-in-docker"
        }}>{`Run Node-RED in Docker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#configuring-your-dashboard"
        }}>{`Configuring your Dashboard`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#used-node-red-nodes"
            }}>{`Used Node-RED Nodes:`}</a></li>
        </ul>
      </li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Docker is an application that simplifies the process of managing application processes in containers. Containers let you run your applications in resource-isolated processes. They’re similar to virtual machines, but containers are more portable, more resource-friendly, and more dependent on the host operating system.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/627d0efb30adb4a3459744efd6c7ae21/0d98f/INSTAR_Dashboard_Docker_Node-RED_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACiklEQVQoz33Q70sacRzAcf+JHtygPehREPigB5HSoxOXQSvInpx2dvYg8NkFhQQGuk27bZqX13ZltWexuX54sjQZ9CSwB6nTlT/SoMQFgwZlxew8r/sMO1gb+/F+8OXLF15fPt+v4slMkmI+P51J2V9+cvky1Fzu+atDai7vnM04Zw+mmZzLl31GHzhnMw5P2uXLUnP5aSY7zWRdvqyiUjmvfr8GgO3tj7TX/WbZP+N5wb5molubXHB9ednPsgzj825FPhzmM2ur71bfv+WCa4uL81xwXcHXapeXVwCwuLSk0+l6ex+jqEarfdTe3t7ZqWpufmgyDW9sBDkutLkZttvtuMk0PEwgyAMcxxWVSuXs25kkSYFAwGKxEARhNptHR0dVKlVbWxuCIBMTE6VSKRaLRSIRmqZDoZDH42lqaiJJUnF5dXV+cQEANE2jKNrX16dWq/v7+7u7u7u6ulpbW9VqNcdx8Xh8b29vampqZWXFYrG0tLQQBKEAgNvbWwAIh8M2m218fBzHcavV6nA4nE6n3W4nSXJnZ6dQKHAc5/V6d3d3GYaxWq0URTWwJEkAUCwWE4lEOp2OxWLJZLJcLheLxVQqdXx8LIoiz/PValUQBHkj18CiKAKA3+/v6enR6/Uoiup0usHBQaPRqFQqHQ4HANzc3AiCUKvV5FXuHgcCAbPZPDQ0ZDKZDAYDhmF6vR5BEJZlf+Jf+w0vLCwYMMxoNA4MDGAYptFoRkZGbDZbNBoFAJ7nhT+6x+z8vDywUqnUarUdHR2Tk5Pk2Jjb7f4flj/s6+mX0slJLpdLJOLJZGJ/f//o6KiQz5XLZVEU5df+BTc8QP26It2NAGL97qhxI/BVqV4X/tEPAhoidZjx/SAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/627d0efb30adb4a3459744efd6c7ae21/e4706/INSTAR_Dashboard_Docker_Node-RED_01.avif 230w", "/en/static/627d0efb30adb4a3459744efd6c7ae21/d1af7/INSTAR_Dashboard_Docker_Node-RED_01.avif 460w", "/en/static/627d0efb30adb4a3459744efd6c7ae21/7f308/INSTAR_Dashboard_Docker_Node-RED_01.avif 920w", "/en/static/627d0efb30adb4a3459744efd6c7ae21/8faeb/INSTAR_Dashboard_Docker_Node-RED_01.avif 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/627d0efb30adb4a3459744efd6c7ae21/a0b58/INSTAR_Dashboard_Docker_Node-RED_01.webp 230w", "/en/static/627d0efb30adb4a3459744efd6c7ae21/bc10c/INSTAR_Dashboard_Docker_Node-RED_01.webp 460w", "/en/static/627d0efb30adb4a3459744efd6c7ae21/966d8/INSTAR_Dashboard_Docker_Node-RED_01.webp 920w", "/en/static/627d0efb30adb4a3459744efd6c7ae21/63399/INSTAR_Dashboard_Docker_Node-RED_01.webp 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/627d0efb30adb4a3459744efd6c7ae21/81c8e/INSTAR_Dashboard_Docker_Node-RED_01.png 230w", "/en/static/627d0efb30adb4a3459744efd6c7ae21/08a84/INSTAR_Dashboard_Docker_Node-RED_01.png 460w", "/en/static/627d0efb30adb4a3459744efd6c7ae21/c0255/INSTAR_Dashboard_Docker_Node-RED_01.png 920w", "/en/static/627d0efb30adb4a3459744efd6c7ae21/0d98f/INSTAR_Dashboard_Docker_Node-RED_01.png 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/627d0efb30adb4a3459744efd6c7ae21/c0255/INSTAR_Dashboard_Docker_Node-RED_01.png",
              "alt": "Ausführen eines INSTAR Node-RED Dashboards in Docker",
              "title": "Ausführen eines INSTAR Node-RED Dashboards in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "install-docker-on-ubuntu-2004",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#install-docker-on-ubuntu-2004",
        "aria-label": "install docker on ubuntu 2004 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Install Docker on Ubuntu 20.04`}</h2>
    <p>{`First, update your existing list of packages:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` update`}</code></pre></div>
    <p>{`Next, install a few prerequisite packages:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` apt-transport-https ca-certificates `}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` software-properties-common`}</code></pre></div>
    <p>{`Then add the GPG key for the official Docker repository:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -fsSL https://download.docker.com/linux/ubuntu/gpg `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` apt-key `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` -`}</code></pre></div>
    <p>{`Add the Docker repository to APT sources:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` add-apt-repository `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"deb [arch=amd64] https://download.docker.com/linux/ubuntu focal stable"`}</span></code></pre></div>
    <p>{`Now update the package database with the Docker packages:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` update`}</code></pre></div>
    <p>{`Finally, install Docker:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` docker-ce`}</code></pre></div>
    <p>{`Docker should now be installed, the daemon started, and the process enabled to start on boot. Check that it’s running:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` systemctl status docker

docker.service - Docker Application Container Engine
     Loaded: loaded `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`/lib/systemd/system/docker.service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` enabled`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` vendor preset: enabled`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
     Active: active `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`running`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`If you want to avoid typing sudo whenever you run the docker command, add your username to the docker group:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`usermod`}</span>{` -aG docker `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${`}<span parentName="span" {...{
              "className": "token environment constant"
            }}>{`USER`}</span>{`}`}</span></code></pre></div>
    <p>{`To apply the new group membership, log out of the server and back in, or type the following:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`su`}</span>{` - `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${`}<span parentName="span" {...{
              "className": "token environment constant"
            }}>{`USER`}</span>{`}`}</span></code></pre></div>
    <p>{`Now test that everything is set up by typing:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker info`}</code></pre></div>
    <h2 {...{
      "id": "download-node-red-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#download-node-red-configuration",
        "aria-label": "download node red configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Download Node-RED Configuration`}</h2>
    <p>{`We now need a folder that the Node-RED container can use to persist it's data. `}<a parentName="p" {...{
        "href": "https://github.com/mpolinowski/instar_nodered_docker"
      }}>{`Clone this repository`}</a>{` or `}<a parentName="p" {...{
        "href": "https://github.com/mpolinowski/instar_nodered_docker/archive/master.zip"
      }}>{`download the Zip file and unzipp it`}</a>{` and save it into the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt`}</code>{` directory. Make sure that the Docker user has access to this directory and is allowed to write into it. E.g. :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` -R nodered:nodered /opt/nodered/*
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` -R `}<span parentName="code" {...{
            "className": "token number"
          }}>{`777`}</span>{` /opt/nodered`}</code></pre></div>
    <h2 {...{
      "id": "run-node-red-in-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#run-node-red-in-docker",
        "aria-label": "run node red in docker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Run Node-RED in Docker`}</h2>
    <p>{`Start by pulling the `}<a parentName="p" {...{
        "href": "https://hub.docker.com/r/nodered/node-red"
      }}>{`official Node-RED image from Docker Hub`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker pull nodered/node-red:latest`}</code></pre></div>
    <p>{`Now run the Node-RED container with the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run -d --rm --privileged --net`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`host -v /opt/nodered/data:/data --name nodered nodered/node-red:latest`}</code></pre></div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`-d`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Run the container `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`detached`}</code>{` from my terminal in the background`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`-rm`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When I stop the container remove it`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`--privileged`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is a bit like running the process with `}<em parentName="td">{`sudo`}</em>{` and prevents you from running into LINUX security restrictions. You can remove this flag. If your permissions are set correctly the container should work without it.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`--net=host`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Usually Docker container are executed in an virtual network. To be able to access a service outside of localhost you have to open the necessary ports - you can replace this flag with `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`-p 1880:1880`}</code>{` to only open the port that the Node-RED UI needs. If you install additional services in Node-RED that requirer different ports you have to add all of them in the `}<strong parentName="td">{`RUN`}</strong>{` command. Setting the network to `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`host`}</code>{` is just a shortcut for local development.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`-v /opt/Node-RED/data:/data`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This mounts the folder that you created into the container. All data that is stored by Node-RED inside the container will be persisted inside this folder.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`--name nodered`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Give your container a name so you can address it by this name instead of having to know it's ID`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`nodered/node-red:latest`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Is the image that you want to spin up into this container`}</td>
        </tr>
      </tbody>
    </table>
    {/* podman run -d --rm --privileged --net=host -v /opt/Node-RED/data:/data --name nodered localhost/nodered/node-red */}
    <h2 {...{
      "id": "configuring-your-dashboard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-your-dashboard",
        "aria-label": "configuring your dashboard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring your Dashboard`}</h2>
    <h3 {...{
      "id": "used-node-red-nodes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#used-node-red-nodes",
        "aria-label": "used node red nodes permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Used Node-RED Nodes:`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://flows.nodered.org/node/node-red-contrib-ui-state-trail"
        }}>{`node-red-contrib-ui-state-trail`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://flows.nodered.org/node/node-red-contrib-onvif"
        }}>{`node-red-contrib-onvif`}</a>{` (Optional - `}<a parentName="li" {...{
          "href": "/en/Advanced_User/Node-RED_Dashboard_Live_Video/"
        }}>{`there are other ways to embed the live video`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://flows.nodered.org/node/node-red-contrib-moment"
        }}>{`node-red-contrib-moment`}</a>
        <a parentName="li" {...{
          "href": "https://flows.nodered.org/node/node-red-contrib-alasql"
        }}>{`node-red-contrib-alasql`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://flows.nodered.org/node/node-red-node-base64"
        }}>{`node-red-node-base64`}</a></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "873px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4d7f19b0b9ee1ec28555e0cabb9378b6/35751/INSTAR_Dashboard_Docker_Node-RED_00.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACQElEQVQoz12RS28TMRRG8/8llrABobIEQdUFEpUqIZAA0ULT0IY0zaRpm8lMZsaeh33t69fYRkkEbTn748/2GUgprbXB+7qum7ZlHAghlNKyLNM0Xa/XRVHkeU4IyfKsbduu6wghUkoAGHjvGWO0blrGUHBvMUaPiACAiF3XIaJzru97pVT4S4yxruuBMWY8X349u7qY5+XiS5vsSzplgAI4StyZzjnvNyf6LX3fxxgppYO+76UUjHFrndaIEqwxUkrOmdxirTVbpJT/y9ba/bP0wxUd5fAxuX72Y/R8dJFUhDV1xxhwDgAcgPEND2VCyGZZKA2IUimpVMNZB0JIzRkY40AYRGNRWpQOMYSw80MImw8TXMzPk/loVq2rMm+KtGnrLMuGFbmsi2k6fpvfHh/8nu2dnr2fJta5sF323mutB8bYtmGSISW0WJcotUJArLVhGjsNaUOX307fHQ9fjy8PDBIfYt/3IQSl1MA5p7VSGrclYu+Ddb3WlnMw1oHQHDYPcm2j17ndbt7LQuBi/nPya69cXX5Pxk8+Hx7NEiWgqEoQUBbrLM8qSrum6arKGBNCuJd777uWZ2lZE7Zafbq+PWRS+N7XNWWMdVvatqWUdoxprR/JxiiAFgRHVN7HEKP30YfAQaAQSsrg3C601tpa++jaVTm9Gr+aTV4u75LbJF/OFnfzFWsK72SnVClkCcI49y/Sv1QbGUBW5SS/e9M0N1CtbHqa3YwWkwNRnhzNr5+eDF8Mz9ccYoy7Qg/lP1qsEgxBaCC6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d7f19b0b9ee1ec28555e0cabb9378b6/e4706/INSTAR_Dashboard_Docker_Node-RED_00.avif 230w", "/en/static/4d7f19b0b9ee1ec28555e0cabb9378b6/d1af7/INSTAR_Dashboard_Docker_Node-RED_00.avif 460w", "/en/static/4d7f19b0b9ee1ec28555e0cabb9378b6/7bc21/INSTAR_Dashboard_Docker_Node-RED_00.avif 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4d7f19b0b9ee1ec28555e0cabb9378b6/a0b58/INSTAR_Dashboard_Docker_Node-RED_00.webp 230w", "/en/static/4d7f19b0b9ee1ec28555e0cabb9378b6/bc10c/INSTAR_Dashboard_Docker_Node-RED_00.webp 460w", "/en/static/4d7f19b0b9ee1ec28555e0cabb9378b6/d21af/INSTAR_Dashboard_Docker_Node-RED_00.webp 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d7f19b0b9ee1ec28555e0cabb9378b6/81c8e/INSTAR_Dashboard_Docker_Node-RED_00.png 230w", "/en/static/4d7f19b0b9ee1ec28555e0cabb9378b6/08a84/INSTAR_Dashboard_Docker_Node-RED_00.png 460w", "/en/static/4d7f19b0b9ee1ec28555e0cabb9378b6/35751/INSTAR_Dashboard_Docker_Node-RED_00.png 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4d7f19b0b9ee1ec28555e0cabb9378b6/35751/INSTAR_Dashboard_Docker_Node-RED_00.png",
              "alt": "Ausführen eines INSTAR Node-RED Dashboards in Docker",
              "title": "Ausführen eines INSTAR Node-RED Dashboards in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To connect your INSTAR Full HD camera you need to:`}</p>
    <ol>
      <li parentName="ol">{`Double-click the ONVIF node and add you cameras `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
        }}>{`IP Address`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/ONVIF/"
        }}>{`ONVIF Port`}</a>{` and `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/System/User/"
        }}>{`Admin Login`}</a>{`.`}</li>
      <li parentName="ol">{`Double-click the `}<strong parentName="li">{`GET Snap`}</strong>{` HTTP node and add your cameras `}<a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8015_HD/Video_Streaming/"
        }}>{`snapshot URL`}</a>{`:`}
        <ul parentName="li">
          <li parentName="ul">{`JPG Path 1: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`http://192.168.x.x/tmpfs/snap.jpg?usr=usernam&pwd=password`}</code></li>
          <li parentName="ul">{`JPG Path 2: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`http://192.168.x.x/tmpfs/auto.jpg?usr=usernam&pwd=password`}</code></li>
          <li parentName="ul">{`JPG Path 3: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`http://192.168.x.x/tmpfs/auto2.jpg?usr=usernam&pwd=password`}</code></li>
        </ul>
      </li>
      <li parentName="ol">{`Double-click the `}<strong parentName="li">{`Alarmserver`}</strong>{` MQTT IN node and click to edit the `}<strong parentName="li">{`Server`}</strong>{` (Broker). Now you can add your cameras `}<a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Node-RED/"
        }}>{`MQTT broker configuration`}</a>{`.This is the broker that is used by all MQTT nodes - so you only have to change it in one place. `}<strong parentName="li">{`Note`}</strong>{` that if you are using an external MQTT Broker and your camera is connected as a client you can use your broker configuration here. `}<strong parentName="li">{`But`}</strong>{` you will have to change the MQTT Topics in `}<strong parentName="li">{`ALL`}</strong>{` MQTT nodes from the `}<a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
        }}>{`local topic to the camera specific topic using your cameras MQTT ID`}</a>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/44be0074303ff3b7c99b5fab7f875ac8/a3a5c/INSTAR_Dashboard_Docker_Node-RED_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABwklEQVQoz2WRyY5TMRBF89OIHljQgnQvYMFPIWiygI9ggQJ5yXPNk42cCKUlrrwoWXXK17c2bz/stp9+PHz8fvP+693T7v58brbPl/rN0+52+3z3+O1yf7t9fvXw+fW7L/dPu7vH3Qba0YxGL1OJkDF6H8Nd3LD3GLNmNx5nRVhbDxk6xqjyjZohErPQWREl1oEEkDyqSW9kogbS1dJmM/Y+qkpENqf1dFgOgMhM7n4en4QIgMRG1k2VxNnOsGprTdUyy8w3a5uKCGZW1d57RBARIlXlyr2BEHOTARwXeL/fI0JmbMY/IWJrLSPEagWZsyzUh6mKmnjvvSIcAFRVLVDyCgvLurbRS2Mcm0BbKxN1nECIMPvw7KeVlsPBvNh64xcvt9aOp2NkRkx7iAzzq6Ui67pWpUU1VCaqKrP4s8gVJuJLYCV0/PXz92FxNU5dcBWW2aFJe1C38556A73CZiYi7jNJVa2qGUTqSdHNPUu8i85VEYt5qb2wzczLshBRZqoqAJhpRs2EAcQKdLAoQGtk5uHuV1hViAW1MkNEAICts3VRBUSQqtnvosp6rl7C07aq+Zw5YWT1ykx3n6sx/19/ASOC3zqgNNawAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/44be0074303ff3b7c99b5fab7f875ac8/e4706/INSTAR_Dashboard_Docker_Node-RED_02.avif 230w", "/en/static/44be0074303ff3b7c99b5fab7f875ac8/d1af7/INSTAR_Dashboard_Docker_Node-RED_02.avif 460w", "/en/static/44be0074303ff3b7c99b5fab7f875ac8/7f308/INSTAR_Dashboard_Docker_Node-RED_02.avif 920w", "/en/static/44be0074303ff3b7c99b5fab7f875ac8/71423/INSTAR_Dashboard_Docker_Node-RED_02.avif 1277w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/44be0074303ff3b7c99b5fab7f875ac8/a0b58/INSTAR_Dashboard_Docker_Node-RED_02.webp 230w", "/en/static/44be0074303ff3b7c99b5fab7f875ac8/bc10c/INSTAR_Dashboard_Docker_Node-RED_02.webp 460w", "/en/static/44be0074303ff3b7c99b5fab7f875ac8/966d8/INSTAR_Dashboard_Docker_Node-RED_02.webp 920w", "/en/static/44be0074303ff3b7c99b5fab7f875ac8/4d3e0/INSTAR_Dashboard_Docker_Node-RED_02.webp 1277w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/44be0074303ff3b7c99b5fab7f875ac8/81c8e/INSTAR_Dashboard_Docker_Node-RED_02.png 230w", "/en/static/44be0074303ff3b7c99b5fab7f875ac8/08a84/INSTAR_Dashboard_Docker_Node-RED_02.png 460w", "/en/static/44be0074303ff3b7c99b5fab7f875ac8/c0255/INSTAR_Dashboard_Docker_Node-RED_02.png 920w", "/en/static/44be0074303ff3b7c99b5fab7f875ac8/a3a5c/INSTAR_Dashboard_Docker_Node-RED_02.png 1277w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/44be0074303ff3b7c99b5fab7f875ac8/c0255/INSTAR_Dashboard_Docker_Node-RED_02.png",
              "alt": "Ausführen eines INSTAR Node-RED Dashboards in Docker",
              "title": "Ausführen eines INSTAR Node-RED Dashboards in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      